

#bid {
 
  font-size: 24px; 
  height: 40px;
   width: 370px;
}

#planid {
  /* position: absolute; */
  font-size: 24px; 
  height: 40px;
   width: 370px;
   /* width: calc(100% - 200px); */

}


#number {
 
  font-size: 24px; 
  height: 40px;
   width: 370px;
}

#reason {
 
  font-size: 24px; 
  height: 40px;
   width: 370px;
}

#parent { 

  position: absolute;

  width: calc(100% - 200px);

  padding: 5px;

  text-align: center;

  font-size: 24px;  

} 



.notify-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: flex-start;
  align-content: flex-start;
  position: absolute;
  top: 100%;
  right: 10%;
}

.notify-item {
  width: 250px;
  margin: 5px 10px;
  color: #FFF;
  border-radius: 5px;
}

.notify-item:hover {
  opacity: 0.8;
  box-shadow: 0 0 10px 0 rgb(15, 15, 15);
}

.notify-item > p {
  font-family: 'Lora', serif;
  margin: 10px;
  opacity: .8;
}

.notify-item.success {
  background-color: rgba(81, 163, 81, 0.4);
}

.notify-item.error {
  background-color: rgba(203, 100, 94, 0.8);
}

.notify-item.info {
  background-color: rgba(33, 150, 243, 0.8);
}

.notify-title {
  font-weight: 700;
}

.field {
  width: 100%;
  height: 56px;
  border-radius: 4px;
  position: relative;
  background-color: rgba(255,255,255,0.3);
  transition: 0.3s all;
}

.field:hover {
  background-color: rgba(255, 255, 255, 0.45);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}

